.ContentContainer {
  padding: 2em;
}

.MyImage {
  border-radius: 50%;
  margin-left: 50%;
}
.Highlighted {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 50%,
    #f3ad3d6c 50%
  );
}

.IntroText {
  width: 60%;
  font-size: 1.5em;

  text-align: left;
  line-height: 1.5;
}

.IntroText > .Highlighted {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #a3b4ac 50%);
}

.Emote {
  font-size: 2em;
  margin: 5px;
  position: relative;
  top: 0.1em;
}

.ContentFlex {
  display: flex;
}

.Banner {
  margin-left: 1.5em;
  background-size: 300px;
  width: 80%;

  border-radius: 10px;

  font-weight: 800;
  font-size: 1em;
}

.ContactInfo {
  margin: 2em 0;
  padding: 2em;
}

.InstaIcon {
  margin-right: 0.5em;
  position: relative;
  top: 5px;
}

.Section {
  min-height: fit-content;
}

.SectionFlex {
  display: flex;
}

.SectionGreenLeft {
  z-index: -1;
  position: absolute;
  top: 8em;
  left: 0;
  width: 150px;
  height: 300px;
  background-color: #a3b4ac;
  border-radius: 0px 150px 150px 0px;
}

.SectionHeadline {
  font-weight: 900;
}
.SectionContentHeader {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  margin: 75px 0 0 75px;
}

.Divider {
  padding-top: 5em;
  border-bottom: 3px solid #e4c9b8;
  width: 60%;
  margin: 0 auto;
}

.SectionContentText {
  display: flex;
}
.ButtonContainer {
  margin-left: 200px;
}

.SectionContent {
  width: 60%;
  margin: 0 auto;
  padding-top: 1em;
}

.SectionContent > .SectionHeadline {
  font-size: 30px;
  margin-left: 10%;
}

.SurferGirl {
  width: 300px;
  height: 300px;
  margin: 2em 0;
}

.Gallery {
  width: fit-content;
  margin: 0 auto;
  text-align: center;
}

.GalleryContainer {
  display: flex;
  width: fit-content;
  gap: 10px;
  margin: 1em auto;
}

.GalleryItem {
  background-color: aliceblue;
  width: 150px;
  height: 150px;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.GalleryItemImage {
  width: inherit;
}
